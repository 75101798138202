:root {
	--headerHeight: 50px;
	--footerHeight: 50px;
}

.ccLicenseBanner {
	padding-top: 3px;
	height: 30px;
}

/* Main App Element, containing all sub-elements */
.appContainer {
	width: 100%;
	min-height: 100vh;
	display: grid;
	grid-template-rows: var(--headerHeight) calc(100vh - calc(var(--headerHeight) + var(--footerHeight))) var(--footerHeight);
}
.heightFixForSafari {
	/*
		as Safari has the habit to not exclude the nav bar from the
		available screen height we need to work around using this
	*/
	grid-template-rows: var(--headerHeight) auto var(--footerHeight);
	min-height: -webkit-fill-available;
}

/* Main Content Element, containing the things to be shown for a page or route */
.mainContentContainer {
	width: 100%;
	text-align: center;
	background-color: #333;
	overflow-x: hidden;
  overflow-y: auto;
	max-height: 100%;
}

.leaflet-container {
  width: 100%;
  height: 100%;
}

.appHeader {
	text-align: center;
}

.appFooter {
	display: grid;
	height: 100%;
	align-items: center;
	vertical-align: middle;
	grid-template-columns: 70px auto auto;
}
.appFooterLeft {
	text-align: left;
}
.appFooterCenter {
	text-align: center;
	
}
.appFooterRight {
	text-align: right;
	
}

/* old navigation links in teh footer */
.navLink {
	font-size: larger;
	color: #dddade;
}

.navLink:visited {
	font-size: larger;
	color: #aaa;
}

/* container for text content */
.textContentContainer {
	padding-left: 25px;
	padding-right: 25px;
	max-width: 550px;
	margin-left: auto;
	margin-right: auto;
}

.textLeft {
	text-align: left;
}

.textCentered {
	text-align: center;
}

.textRight {
	text-align: right;
}

/* mobile menu */
.mobileMenuToggleButton {
	cursor: pointer;
	margin-top: -7px;
	padding-left: 20px;
	padding-right: 20px;
	font-size: 40px;
}

.mobileMenuContent {
	z-index: 999;
	position: absolute;
	bottom: var(--footerHeight);
	padding: 15px 25px;
	background-color: #282828;
}
.mobileMenuLink:not(:first-child)::before {
	content: "\A";
	white-space: pre;
}

.mobileMenuLink:not(:first-child):not(:last-child) {
	padding-top: 5px;
	padding-bottom: 5px;
}
.mobileMenuLink:first-child {
	padding-top: 10px;
}
.mobileMenuLink:last-child {
	padding-bottom: 10px;
}
.mobileMenuLink {
	font-size: larger;
	display: block;
	color: #dddade;
}